import './LandingPage.css';
import React, { useState, useMemo, useEffect } from 'react';
import image1 from './EventerAIimages/image1.png';
import image2 from './EventerAIimages/image2.png';
import image3 from './EventerAIimages/image3.png';
import linkedin from './EventerAIimages/logoLinkedin.png';
import logoBranco from './EventerAIimages/LogoBranco.png';
import { Analytics } from '@vercel/analytics/react';
// import instagram from './EventerAIimages/instagram.png'; 
//import tiktok from './EventerAIimages/tiktok.png'; 
//import facebook from './EventerAIimages/facebook.png';

function Landingpage() {
  const stepTitles = [
    'Inicie seu atendimento', 'Configure seus lembretes', 'Receba dicas de cuidados' ,'Receba descontos dos seus produtos preferidos'
  ];
  const stepDescriptions = [
    'Inicie sua jornada com a gente clicando em "Inicie agora" no WhatsApp. Enviaremos perguntas iniciais para personalizar nosso serviço conforme as necessidades do seu pet. Estamos prontos para te ajudar!',
    'Evite esquecimentos! Configure lembretes personalizados para vacinas, banhos, remédios e até mesmo o aniversário do seu pet. Mantenha tudo em dia com a praticidade que seu companheiro merece.',
    'Dúvidas sobre os melhores cuidados? Estamos aqui para te orientar. Receba dicas especializadas para garantir o bem-estar do seu pet. Não hesite em nos perguntar - estamos comprometidos com o seu amigo peludo!',
    'Economize sem esforço! Enviaremos comparativos de preços e descontos dos produtos que você adora. Tenha acesso a ofertas exclusivas e proporcione ao seu pet o melhor, sem comprometer seu bolso.'
  ];

  // Define cardsData using useMemo to memoize it
  const cardsData = useMemo(() => [
    {
      image: image1,
      title: 'Descubra Promoções nos Itens Essenciais para Seu Pet!',
      description:
      'Sabemos que os cuidados essenciais para o bem-estar do seu pet podem pesar no bolso. Fique tranquilo! Mantemos você informado sobre promoções nos itens que você já compra, economizando tempo e dinheiro com escolhas acertadas!',
    },
    {
      image: image2,
      title: 'Receba Dicas Personalizadas para Uma Vida Extraordinária com Seu Pet!',
      description:
      'Desfrute de uma vida extraordinária com seu pet! Oferecemos as melhores dicas personalizadas sobre cuidados, passeios, lugares e produtos. Tudo gratuito e adaptado às suas preferências. Sua jornada com o pet nunca foi tão incrível!',
    },
    {
      image: image3,
      title: 'Assistência Pessoal: Suas Dúvidas sobre Cuidados com Seu Pet, Esclarecidas!',
      description:
      'Dúvidas sobre cuidados com seu pet? Estamos aqui para ajudar! Envie-nos suas perguntas, somos seu assistente pessoal dedicado a esclarecer todas as necessidades do seu pet. Conte conosco a qualquer momento!',    
    },
    {
      image: image1,
      title: "Lembretes Personalizados para Todos os Cuidados Necessários com Seu Pet!",
      description:
        'Mantenha todos os cuidados em dia! Utilize nossa função de lembretes para garantir que nada seja esquecido. Configure alertas para aplicação de remédios, banhos, tosas, vacinas e até o aniversário do seu pet. Nunca foi tão fácil cuidar com carinho do seu melhor amigo!'
    }
  ], []);

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? cardsData.length - 1 : prevIndex - 1));
  };
  
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cardsData.length - 1 ? 0 : prevIndex + 1));
  };

  // Add a state variable to track screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update screenWidth when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Analytics />
      <header className="header">
        <div className="logo">
          <div className='logo-container'>
            <img src={logoBranco} alt="Eventer.AI Logo" className= "logo"/>
          </div>
        </div>
        {screenWidth > 1023 && ( 
          <a
            href="https://wa.me/5511914454530?text=Cadastro!"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-button"
          >
            Inicie agora
          </a>
        )}
      </header>

{/* First Content Section */}
      <section1 className="hero-section">
        <div className="content">
          <h1 className='callout'>
          Assistência <span className='highlight-callout'>personalizada</span> para tutores de pets!
          </h1>
          <p className="secondary-text">
          Seu companheiro merece o melhor, e nós tornamos isso possível! Receba dicas, descontos exclusivos e lembretes personalizados para garantir o máximo de cuidado e alegria para seu pet.
          </p>
          <br/>
          <div className='button-box'>
          <a
            href="https://wa.me/5511914454530?text=Cadastro!"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-button-blue"
            >
            Inicie agora
          </a>
          </div>
          <div className='button-box'>
          <a
            href = "https://wa.me/5511972011525"
            target = "_blank"
            rel="noopener noreferrer"
            className="contact-button-black"
            >
            Quero ser parceiro
          </a>
          </div>
          <p className="secondary-text-bold">Inicie agora ou preencha o formulário abaixo!</p>
        </div>
        <br/>
      </section1>
      
{/* Manifesto Section */}      
      <div className='manifesto'>
      <div className='manifesto-container'>
      <h2>Manifesto da Datapaws</h2>
      <p>
        Somos mais do que uma marca; somos uma comunidade unida por uma paixão inabalável pelos nossos fiéis amigos de quatro patas. A Datapaws nasceu do amor incondicional que compartilhamos com nossos pets e da crença profunda de que cada momento ao lado deles é especial.
      <br/>
      <br/>
        Aqui, celebramos a alegria de acordar todas as manhãs com um amigo peludo ao nosso lado. Defendemos a importância de uma vida saudável e feliz para os nossos companheiros, repletos de passeios, brincadeiras e todo o carinho que merecem.
      <br/>
      <br/>
        Nós entendemos que ser tutor de um pet é uma jornada cheia de desafios e alegrias, e estamos aqui para tornar essa jornada mais fácil, mais divertida e mais recompensadora. Com dicas úteis, lembretes personalizados e ofertas exclusivas, queremos simplificar a vida dos tutores e garantir que cada pet viva a sua melhor vida.
      <br/>
      <br/>
        Na Datapaws, acreditamos que todos os pets merecem amor, cuidado e atenção individualizada. Estamos comprometidos em ser mais do que uma marca – somos seus parceiros na jornada de cuidar, nutrir e criar memórias inesquecíveis com seus companheiros peludos.
      <br/>
      <br/> 
        Juntos, celebramos a extraordinária conexão entre humanos e pets. Juntos, somos a Datapaws - porque a vida com pets é mais rica, mais significativa e, acima de tudo, mais amorosa.
      </p>
          {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeIWw8hA2opfTB7YO-6ChVt7tYjz5HNv2jNVZyyWZb8Sd3xzQ/viewform?embedded=true" title="Contact form" frameborder="0">
          Loading...
            </iframe> */}
      </div>
      </div>


{/* Fourth Content Section */}
      <sectionprov className="content-sectionprov" style={{ backgroundColor: '#00356e' }}>
        <div className="contentprov">
        <div className="step-process">
            {stepTitles.map((title, index) => ( 
              <div key={index} className="step">
                <div className='step-number'>
                  <span className="step-number-background">{index + 1}</span>
                </div>
                <div className="step-info">
                  <div className="step-title">{title}</div>
                  <div className="step-description">{stepDescriptions[index]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </sectionprov>

{/* Second Content Section */}
      <section2 className="content-section2">
        <div className="content2">
          <div className="slider-controls">
            <button className="prev-button" onClick={prevSlide}>
              &#8249;
            </button>
          <div className="slideshow-container">
            {cardsData.map((card, index) => (
              <div key={index} className={`card ${index === currentIndex ? 'active' : ''}`}>
                <div className="card-content">
                {screenWidth > 1023 && (
                <img src={card.image} alt={`Card ${index}`} className="card-image" />
                )}
                  <div className="text-block">
                    <p className="bold-text">{card.title}</p>
                    <p className="normal-text">{card.description}</p>
                  </div>
                </div>
                <div className='cta-container'>
            <div className="cta-button-container">
              <a href="https://wa.me/5511914454530?text=Cadastro!" className="cta-button contact-button">
                Inicie agora
              </a>
            </div>
          </div>
              </div>
            ))}
          </div>
            <button className="next-button" onClick={nextSlide}>
               &#8250;
            </button>
          </div>
          <div className="dots-parent">
          <div className="dots-container">
            {cardsData.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? 'active-dot' : ''} `}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
          </div>
        </div>
      </section2>


{/* Footer */}
      <footer className="footer">
      {screenWidth > 1023 && (
        <div className="footer-left">
          <a href="https://www.linkedin.com/in/joaquim-prado/">
            <img src={logoBranco} alt="Company Logo" className='logo'/>
          </a>
        </div>
      )}
        <div className="footer-right">
         {/*<a href="https://instagram.com/eventer.ai?igshid=OGQ5ZDc2ODk2ZA==" style={{ marginRight: '25px' }}>
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="https://www.tiktok.com/@eventer.ai?_t=8fpdrJpTDTZ&_r=1" style={{ marginRight: '25px' }}>
            <img src={tiktok} alt="TikTok" /> 
          </a>
          <a href="https://www.facebook.com/people/Eventerai/61551506941290/" style={{ marginRight: '25px' }}>
            <img src={facebook} alt="Facebook" /> 
          </a> */}
          <a href="https://www.linkedin.com/in/joaquim-prado/">
            <img src={linkedin} alt="LinkedIn" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Landingpage;